<template>
    <div id="app" :theme="theme">
        <router-view @switchTheme="switchTheme" :theme="theme"/>
    </div>
</template>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;700;800;900&family=Epilogue:wght@300;400;500;600;700;800;900&family=Heebo:wght@300;400;500;800;900&family=Open+Sans:wght@400;700;800&family=Signika+Negative:wght@300;400;600;700&family=Signika:wght@400;600;700&display=swap');

    #app {
        &[theme="light"] {
            background-color: #FFFFFF;
        }

        &[theme="dark"] {
            background-color: #151515;
        }

        &.modal-open {
            padding-right: 0 !important;
        }
    }

    ::selection {
        background-color: #4D21A9;
        color: #FFFFFF;
    }

    .unselect {
        -webkit-user-select: none;
        -moz-user-select: none;     
        -ms-user-select: none;    
        user-select: none;
    }

    p , a , i , h1 , h2, h3, h4 {
        font-family: 'Open Sans', sans-serif;
    }

    text-center {
        text-align: center;
    }
</style>

<script>
    export default {
        name: 'App',
        data() {
            return {
                theme: 'light'
            }
        },
        metaInfo() {
            return {

            }
        },
        mounted() {
            this.theme = localStorage.getItem('theme') || 'light';
        },
        methods: {
            switchTheme() {
                const newTheme = ['light', 'dark'].find(theme => theme !== this.theme);
                if(newTheme) {
                    this.theme = newTheme;
                    localStorage.setItem('theme', newTheme);
                }
            }
        }
    }
</script>