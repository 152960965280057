import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import VueScrollTo from 'vue-scrollto';
import VueTypedJs from 'vue-typed-js';
import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(VueTypedJs);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
