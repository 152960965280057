import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue')
}, {
    path: '/portfolio',
    name: 'Portfólio',
    component: () => import('../views/portfolio.vue')
}, {
    path: '/termos',
    name: 'Termos de Serviço',
    component: () => import('../views/tos.vue')
}, {
    path: '/*',
    name: 'Página não Encontrada',
    component: () => import('../views/404.vue')
}]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;